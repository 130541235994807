import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { BrowserRouter } from 'react-router-dom'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap/dist/js/bootstrap.js'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import ContentData from './Context/Content' 

const queryClient=new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
<QueryClientProvider client={queryClient}>
  <BrowserRouter>
  <ContentData>
    <App />
  </ContentData>
  </BrowserRouter>
  </QueryClientProvider>
)


reportWebVitals()
