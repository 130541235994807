import React, { useContext } from 'react'
import { ContentContext } from '../Context/Content'

const Footer = () => {

  const {SocialMedia}=useContext(ContentContext)
  return (
<div style={{background: 'url(images/footer-bg.png) no-repeat center center / cover'}}>
  <div className="footer-inner">
    <div className="container">
      <div className="row">
        <div className="col-lg-3">
          <a className="navbar-footer" href="index.html">
            <img src="images/logo.png" alt className="img-fluid" />
          </a>
          <div className="footer-para">
            <p>Alma de Samana Golf Resort &amp; Residences is a world-class resort destination located on the southern side of the Samaná Peninsula, nestled upon Samaná Bay.</p>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-box">
            <h5>Useful Link</h5>
            <ul className="foot-list">
              <li>
                <a href="index.html">Our Mission </a>
              </li>
              <li>
                <a href="faq.html">Development </a>
              </li>
              <li>
                <a href="blog.html">The Team </a>
              </li>
              <li>
                <a href="news.html">Contact Us</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-box">
            <h5>Contact</h5>
            <ul className="foot-list">
              <li>
                <a className="contact-link" target="_blank" href="https://goo.gl/maps/itxqdL6TdHb5pJXq7">
                  <i className="fa-solid fa-phone" />
                  <span>(866) 4-ADSSALES</span>
                  <p>FOR SALES</p>
                </a>
              </li>
              <li>
                <a className="contact-link" href="javascript:void(0)">
                  <i className="fa-solid fa-phone" />
                  <span>(833) 4-ALMA-000</span>
                  <p>FOR GENERAL INQUIRIES</p>
                </a>
              </li>
              <li>
                <a className="contact-link" href="javascript:void(0)">
                  <i className="fa-solid fa-envelope" />
                  <span>info@almadesamana.com</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3">
          <div className="footer-box">
            <h5>NEWSLETTER</h5>
            <form className="email-input">
              <input type="email" className="form-input" placeholder="Email" />
              <button className="btn email-img">
                <img src="images/email-icon.png" alt />
              </button>
            </form>
            <ul className="social-list">
              <li><a  target='_sb' href={`${SocialMedia?.facebook}`}><i className="fa-brands fa-facebook-f" /></a></li>
              <li><a  target='_sb' href={`${SocialMedia?.youtube}`}><i className="fa-brands fa-youtube" /></a></li>
              <li><a  target='_sb' href={`${SocialMedia?.instagram}`}><i className="fa-brands fa-instagram" /></a></li>
              <li><a  target='_sb' href={`${SocialMedia?.linkedin}`}><i className="fa-brands  fa-linkedin-in" /></a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div className="footer-social">
    <div className="container">
      <div className="footer-copy-text">
        <p>Copyright © 2023. All Rights Reserved.</p>
      </div>
    </div>
  </div>
</div>
  )
}

export default Footer