import axios from "axios";
import config from "../config";




class LangServices {
    //API_BASE_URL = process.env.NEXT_PUBLIC_API_BASE_URL;

    Language(id){
        return axios.get(`${config.apiUrl}/api/languages`)
    }
  }
  
  export default LangServices = new LangServices();