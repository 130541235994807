import { createContext, useState } from 'react'
import React from 'react'

const ContentContext = createContext()

const ContentData = ({ children }) => {
  const [AboutData, setAboutData] = useState({})
  const [Lang_Id,setLangId]=useState("1")
  const [InvestmentData,setInvestmentData]=useState({})
  const [HomeData,setHomeData]=useState({})
  const [HomeBanner,setHomeBanner]=useState({})
  const [VisionData,setVisionData]=useState({})
  const [SocialMedia,setSocialMedia]=useState({})


  return (
  <ContentContext.Provider value={{ SocialMedia,setSocialMedia,HomeBanner,setHomeBanner, AboutData, setAboutData ,setLangId,Lang_Id,InvestmentData,setInvestmentData,HomeData,setHomeData,VisionData,setVisionData}}>
      {children}
    </ContentContext.Provider>
  )
}

export default ContentData
export { ContentContext }
