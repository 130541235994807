import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import {  useQuery } from '@tanstack/react-query'
import LangServices from '../Services/LangServices'
import { ContentContext } from '../Context/Content'

const Header = () => {
  const { setLangId } = useContext(ContentContext)
  const getLang = (e) => {
    console.log('E.ta', e.target.value)
    // alert(e.target.value)
    // localStorage.setItem('lang_id', JSON.stringify(e.target.value))
    setLangId(e.target.value)
  }

  const { data, isLoading, isError, isFetched, error } = useQuery(
    ['language'],
    () => LangServices.Language(),
  )

  if (isLoading || !isFetched) {
    return <p className="text-danger">Loading....</p>
  }


  console.log("-->",data)

  return (
    <header>
      <nav className="navbar navbar-expand-xl">
        <div className="container-fluid">
          <div className="row w-100">
            <div className="col-xl-5 col-lg-8 col-md-8 col-sm-12">
              <NavLink
                style={({ isActive }) => ({
                  color: isActive ? 'blue' : 'white',
                })}
                className="navbar-brand"
                to={'/'}
              >
                <img src="images/logo.png" alt="logo" className="img-fluid " />
              </NavLink>
            </div>
            <div className="col-xl-7 col-lg-4 col-md-4 col-sm-12">
              <div className="d-flex justify-content-end align-items-center gap-3">
                {
                  isError? <p className='text-danger'>{error?.message}</p>:      <select
                  className="header-select"
                  onChange={getLang}
                  defaultValue={1}
                  aria-label="Default select example"
                >
                  {data?.data?.data.map((ele) => {
                    return (
                      <option key={ele?.id} value={ele?.id}>
                        {ele?.native_name}
                      </option>
                    )
                  })}
                  
                </select>
                }
          
                <div className="dropdown login-btns">
                  <button
                    className="dropdown-toggle"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Login
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <a className="dropdown-item" href="#">
                        OWNER
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        INVESTER
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        REFERRAL AGENT
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        AMBASSADOR
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        VENDOR
                      </a>
                    </li>
                    <li>
                      <a className="dropdown-item" href="#">
                        SPONSERS &amp; PARTNERS
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="head-brand-menu d-flex align-items-center">
                  <button
                    className="navbar-toggler ms-sm-3 ms-2"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarScroll"
                    aria-controls="navbarScroll"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <span className="navbar-toggler-icon" />
                  </button>
                </div>
              </div>
              <div
                className="collapse navbar-collapse nav-menu"
                id="navbarScroll"
              >
                <ul className="navbar-nav ms-auto navbar-nav-scroll">
                  <li className="nav-item">
                    {/* <a className="nav-link" href="index.html">Home</a> */}
                    <NavLink
                      className="nav-link"
                      to={'/'}
                      style={({ isActive }) => ({
                        color: isActive ? '#fff' : 'white',
                      })}
                    >
                      Home
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {/* <a className="nav-link" href="development.html">Development </a> */}
                    <NavLink
                      className="nav-link"
                      to={'/vision'}
                      style={({ isActive }) => ({
                        color: isActive ? '#fff' : 'white',
                      })}
                    >
                      Vision
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {/* <a className="nav-link" href="ownership.html">Ownership </a> */}
                    <NavLink
                      className="nav-link"
                      to={'/ownership'}
                      style={({ isActive }) => ({
                        color: isActive ? '#fff' : 'white',
                      })}
                    >
                      Ownership
                    </NavLink>
                  </li>

                  <li className="nav-item">
                    {/* <a className="nav-link" href="investment.html">Investment </a> */}
                    <NavLink
                      className="nav-link"
                      to={'/investment'}
                      style={({ isActive }) => ({
                        color: isActive ? '#fff' : 'white',
                      })}
                    >
                      Investment
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    {/* <a className="nav-link" href="/about">About Us</a> */}
                    <NavLink
                      className="nav-link"
                      to={'/about'}
                      style={({ isActive }) => ({
                        color: isActive ? '#fff' : 'white',
                      })}
                    >
                      About Us
                    </NavLink>
                  </li>
                  {/* <li className="nav-item"> */}
                  {/* <a className="nav-link" href="partners.html">Partners </a> */}
                  {/* <NavLink
                      className="nav-link"
                      to={'/partners'}
                      style={({ isActive }) => ({
                        color: isActive ? 'blue' : 'white',
                      })}
                    >
                      Partners
                    </NavLink>
                  </li> */}
                  {/* <li className="nav-item">
                    <a className="nav-link" href="/">
                      Podcast
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </header>
  )
}

export default Header
