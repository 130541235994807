import { Route, Routes } from 'react-router-dom'
import React, { Suspense } from 'react'
import Layout from './Utilities/Layout'
const Home = React.lazy(() => import('./Pages/Home'))
const About = React.lazy(() => import('./Pages/About'))
const Development = React.lazy(() => import('./Pages/Development'))
const Investment = React.lazy(() => import('./Pages/Investment'))
const Ownership = React.lazy(() => import('./Pages/Ownership'))
const Partners = React.lazy(() => import('./Pages/Partners'))
const PageNotFound = React.lazy(() => import('./Pages/PageNotFound'))

function App() {
  return (
    <Layout>

   
    <Routes>
      <Route
        path="/"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Home />
            </Suspense>
          </>
        }
      />
      <Route
        path="/about"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <About />
            </Suspense>
          </>
        }
      />
      <Route
        path="/vision"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Development />
            </Suspense>
          </>
        }
      />
      <Route
        path="/investment"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Investment />
            </Suspense>
          </>
        }
      />
      <Route
        path="/ownership"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Ownership />
            </Suspense>
          </>
        }
      />
      <Route
        path="/partners"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <Partners />
            </Suspense>
          </>
        }
      />
      <Route
        path="*"
        exact
        element={
          <>
            <Suspense fallback={<div>Loading...</div>}>
              <PageNotFound />
            </Suspense>
          </>
        }
      />
    </Routes>
    </Layout>
  )
}
export default App
